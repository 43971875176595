import React from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import TituloPagina from "../../../componentes/TituloPagina";

function Produtos1() {
    return(
        <div>
            <BarraLateral/>          
            <div className="GlobalConteinerPrincipal">
                <div className="GlobalConteudoPrincipal">
                    <TituloPagina titulo={"Produtos 1"} />
                </div>
            </div>
        </div>
    );
}

export default Produtos1;