//import { FaPlus, FaSearch, FaSave, FaBackward, FaEdit } from "react-icons/fa";
import BotaoPadrao from "../../../Botoes/BotaoPadrao";
import { CoresGlobal } from "../../../../estilos"

import Estilo from "./estilo.module.css";
import React from "react";

//const TabelaTipo1 = ({id, titulo, Itens, onClick}) =>{
function ItemTabelaPadrao ({id, titulo, Itens, motrarBtnEditar, motrarBtnVisualizar, 
    motrarBtnExcluir, clickVisualizar, clickExcluir, clickEditar}) {        
    return(
        <div className={Estilo.Item_Pesquisa} style={{borderLeft: `0.2em solid ${CoresGlobal.cor_paleta_1}`}}>
            
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0}}>                   
                <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                    <div  style={{width: '100%'}}>
                        <p style={{fontSize: '.9em', fontWeight: 'bold', color: CoresGlobal.cor_paleta_1}}>{titulo}</p>
                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 2fr))', gap: '0.3em', justifyContent: 'space-between'}}>
                            {Object.keys(Itens).map((keyName, i) => (
                                <div style={{fontSize: '.8em'}}>
                                    <p><span style={{fontWeight: 'bold'}}>{keyName}</span>: {Itens[keyName]}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex'}}>
                    {
                        motrarBtnVisualizar ?
                        <BotaoPadrao
                            tipo={'visualizar'} 
                            //estilo={{'background-color': CoresGlobal.cor_paleta_4}}
                            onClick={clickVisualizar}
                        />                        
                        : null
                    }
                    {
                        motrarBtnExcluir ?
                        <BotaoPadrao
                            tipo={'excluir'} 
                            //estilo={{'background-color': CoresGlobal.cor_paleta_4}}
                            onClick={clickExcluir}
                        />                       
                        : null
                    }
                    {
                        motrarBtnEditar ?
                        <BotaoPadrao
                            tipo={'editar'} 
                            //estilo={{'background-color': CoresGlobal.cor_paleta_4}}
                            onClick={clickEditar}
                        />                      
                        : null
                    }                                                                                 
                </div>
                
            </div>
        </div>
    );
};

export default ItemTabelaPadrao;