import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import ItemTabelaPadrao from "../../../componentes/Tabelas/TabelaPadrao/ItemTabelaPadrao";
import Rodape from "../../../componentes/Rodape";
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao';

//<Link to={"/clientes/cadastro"}  state={{ testea: "value" }}>Teste com parâmetro</Link>

class ClientesConsulta extends Component{
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',

            State_Pesq_Valor: '',
            /*
            State_lista_clientes: [
                {cliente_id: 1, razao: 'Fernando aragao bonfim'},
                {cliente_id: 2, razao: 'Teste 1 dfa dsfasd fd as'},
                {cliente_id: 3, razao: 'Teste 2 dfas fsdaf asdf ds'},                                                                                                                      
            ],
            */
            State_lista_clientes: [],            
        }
    };

    Editar_Cliente(cliente_id){
        this.props.navegar("/clientes/cadastro", {state: { cliente_id: cliente_id, status_operacao: 'editar' } });
    }    

    Adicionar_Cliente(){
       this.props.navegar("/clientes/cadastro", { state: { testea: "Valor testeA" } });
    }

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };

    Pesquisar_Cliente(){
        this.setState({
            State_lista_clientes: [],
        });

        var objJson = { 
            Pesq_Valor: this.state.State_Pesq_Valor,
        }

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Cliente_Consulta', 0, JSON.stringify(objJson), this.props.navegar
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                const itens_api = Object.entries(resposta.dados).map( ( item ) => ({ 
                    cliente_id: item[0], razao: item[1][0], cnpj: item[1][1]
                }) ).sort((a, b) => a.razao.localeCompare(b.razao));               
                this.setState({
                    State_lista_clientes: itens_api,
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });
    }

    Renderiza_Itens_Pesquisa = (cliente_id, razao, cnpj) => {
        var objJson = { 
            Cnpj: cnpj,
        };

        return(
            <ItemTabelaPadrao
                id = {cliente_id}
                titulo = {razao}
                Itens={objJson}
                motrarBtnEditar={true}
                clickEditar={() =>{this.Editar_Cliente(cliente_id)}}
            />
        )
    };   

    render(){
        return(
        <div>    
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Clientes > Consulta"} />                 
                    <InputPadrao 
                        //estilo={{ width:'100%', "margin-left": '10px'}}
                        titulo={'Pesquisar'}
                        dica = {'Razão ou Cnpj'}
                        value = {this.state.State_Pesq_Valor}
                        tipo = {'pesquisa'}
                        onClick={() =>{this.Pesquisar_Cliente()}}                    
                        onChange = {valor => this.setState({State_Pesq_Valor: valor.target.value})}
                        onKeyUp={event => {if (event.key === 'Enter') {this.Pesquisar_Cliente()}}}
                        autoFocus = {true}
                    />
                    {
                        this.state.State_lista_clientes.map((item) =>{
                            return(                                
                                this.Renderiza_Itens_Pesquisa(item.cliente_id, item.razao, item.cnpj)
                            )                            
                        })
                    }                  

                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(ClientesConsulta);