import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const BarraLateralDados = [
    {
        titulo: 'Usuarios',
        caminho: '/usuarios/consulta',
        icone: <AiIcons.AiFillHome/>,        
    },    
    {
        titulo: 'Clientes',
        caminho: '/clientes/consulta',
        icone: <AiIcons.AiFillHome/>,        
    },
    {
        titulo: 'Produtos',
        caminho: '#',
        icone: <AiIcons.AiFillHome/>,
        iconeFechado: <RiIcons.RiArrowDownFill/>,
        iconeAberto: <RiIcons.RiArrowUpSFill/>,
        subMenu: [
            {
                titulo: 'produto 1',
                caminho: '/produto/produto1',
                icone: <IoIcons.IoIosPaper/>,                
            },
            {
                titulo: 'produto 2',
                caminho: '/produto/produto2',
                icone: <IoIcons.IoIosPaper/>,                
            }, 
            {
                titulo: 'produto 3',
                caminho: '/produto/produto2',
                icone: <IoIcons.IoIosPaper/>,                
            }, 
            {
                titulo: 'produto 4',
                caminho: '/produto/produto2',
                icone: <IoIcons.IoIosPaper/>,                
            },                                               
        ]        
    },
    {
        titulo: 'Fornecedor',
        caminho: '/fornecedor',
        icone: <FaIcons.FaCartPlus/>,    
    },        
]
