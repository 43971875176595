import React from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import TituloPagina from "../../../componentes/TituloPagina";

//import Estilo from "./estilo.module.css";

function Produtos2() {
    return(
        <div>
            <BarraLateral/>          
            <div className="GlobalConteinerPrincipal">
                <div className="GlobalConteudoPrincipal">
                    <TituloPagina titulo={"Produtos 2"} />
                </div>
            </div>
        </div>
    );
}

export default Produtos2;