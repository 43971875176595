import React, { Component } from "react";
import withRouter from "../../Rotas/rotasParametros";
import Api from '../../servicos/Axios';
import MsgPopUpPadrao from "../../componentes/PopUpModal/MsgPopUpPadrao";
import { Global_Async_Local_Storage } from "../../Funcoes_Globais";
import PopUpLoadPadrao from '../../componentes/PopUpLoad/PopUpLoadPadrao';
import Estilo from "./estilo.module.css";
import Imagem from "../../imagens/Logo/Logo_login_1.png";

class Login extends Component{
    constructor(props){
        super(props);

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            

            state_usuario_email: '',
            state_usuario_senha: '',
        }
    };

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    }; 
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };    

    Logar(){
        //Faz as validações dos campos
        var objJson = { 
            usuario_email: this.state.state_usuario_email,
            usuario_senha: this.state.state_usuario_senha,
        };

        this.Abre_LoadPopUpPadrao('Iniciando Login ...');
        Api.Executa_chamada(
            this.props.navegar, 'Usuario_Login', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            if (resposta.status === 1){              
                //Global_Async_Local_Storage.setItem("access_token", resposta.dados.usuario_access_token);               
                Global_Async_Local_Storage.setItem("refresh_token", resposta.dados.usuario_access_refresh_token);
                Global_Async_Local_Storage.setItem("access_token", resposta.dados.usuario_access_token)

                setTimeout(() => {
                    this.Fecha_LoadPopUpPadrao();
                    this.props.navegar("/load_inicial");
                  }, 1000); 
                
            }else if (resposta.status === 2){
                this.Fecha_LoadPopUpPadrao();
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)        
            }
            else{
                this.Fecha_LoadPopUpPadrao();
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });         

    };

    render(){
        return(
            <div className={Estilo.ConteinerPrincipal}>
                <MsgPopUpPadrao
                    visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                    mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                    tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                    mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                    onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                    onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                    onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
                /> 
                <PopUpLoadPadrao
                    visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                    mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
                />                 
                <div className={Estilo.ConteinerLogin}>
                    <img src= {Imagem} alt=""/>
                    <h2 className={Estilo.Titulo}>Controle de Clientes</h2>
                    <div className={Estilo.ConteinerInputs}>
                        <span className={Estilo.ConteinerInputSpan}>Email</span>
                        <input 
                            type="text"
                            onChange={valor => this.setState({state_usuario_email: valor.target.value})}
                            value = { this.state.state_usuario_email }
                            className={Estilo.ConteinerInputsInput}>
                        </input>
                    </div>
                    <div className={Estilo.ConteinerInputs}>
                        <span className={Estilo.ConteinerInputSpan}>Senha</span>
                        <input 
                            type="password"
                            onChange={valor => this.setState({state_usuario_senha: valor.target.value})}
                            value = { this.state.state_usuario_senha }
                            className={Estilo.ConteinerInputsInput}
                            onKeyUp={event => {if (event.key === 'Enter') {this.Logar()}}}
                            >                            
                        </input>
                    </div>
                    <div className={Estilo.ConteinerBotoes}>
                        <button className={Estilo.ConteinerBotoesButton} onClick={ () => {this.Logar()}}>
                            Entrar
                        </button>
                    </div>                                
                </div>
            </div>
        )
    };
};

export default withRouter(Login);