import React, { Component } from "react";
import BarraLateral from "../../../componentes/BarraLateral";
import InputPadrao from "../../../componentes/Inputs/ImputPadrao";
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import TituloPagina from "../../../componentes/TituloPagina";
import MsgPopUpPadrao from "../../../componentes/PopUpModal/MsgPopUpPadrao";
import InputDataPadrao from "../../../componentes/Inputs/InputDataPadrao";
import TooglePadrao from "../../../componentes/Botoes/TooglePadrao";
import Rodape from "../../../componentes/Rodape";
import { Global_Valida_Data } from "../../../Funcoes_Globais"
import withRouter from "../../../Rotas/rotasParametros";
import Api from '../../../servicos/Axios';
import Estilos from "./estilo.module.css";
import PopUpLoadPadrao from '../../../componentes/PopUpLoad/PopUpLoadPadrao'

//https://www.freecodecamp.org/news/jwt-authentication-in-flask/

class ClientesCadastro extends Component{    
    constructor(props){
        super(props)

        this.state = {
            State_MsgPopUpPadrao_Visivel: false,
            State_MsgPopUpPadrao_Mensagem: '',
            State_MsgPopUpPadrao_Tipo: 1,
            State_MsgPopUpPadrao_Acao: '',
            State_MsgPopUpPadrao_Mostrar_Botoes: true,

            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            

            state_status_operacao: this.props.match.paramsLocation.state.status_operacao,
            state_usuario_id: this.props.match.paramsLocation.state.usuario_id,
            state_usuario_nome: '',
            state_usuario_email: '',
            state_usuario_senha: '',
            state_usuario_ativo: 'N',
            state_usuario_ativo_checkbox: false,
            state_usuario_data_cadastro: '',
        };
        
        //https://stackoverflow.com/questions/70143135/how-to-use-react-router-dom-v6-navigate-in-class-component
        //O Match pega o parâmetro passado de outra tela pelo link, o navegar navega entre as telas
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>");
        //console.log(this.props.match)         
        //console.log(this.props.match.paramsLocation.state.usuario_id);      
        //console.log(this.props.match.paramsLocation.state.status_operacao);
        //console.log(this.state.state_cliente_id)

    };

    componentDidMount (){
        //console.log('Did mount>', this.props.match.paramsLocation.state.status_operacao, this.props.match.paramsLocation.state.usuario_id);
        if (this.state.state_status_operacao === 'editar') {
            this.Pesquisar_Usuario();
        }
    };

    Abre_MsgPopUpPadrao(Tipo = 1, Mostrar_botoes = true, Mensagem = '', Acao_Botao = '', Tempo_fechar = 0){
        this.setState({
            State_MsgPopUpPadrao_Visivel: true,
            State_MsgPopUpPadrao_Tipo: Tipo,
            State_MsgPopUpPadrao_Mensagem: Mensagem,
            State_MsgPopUpPadrao_Acao: Acao_Botao, 
            State_MsgPopUpPadrao_Mostrar_Botoes: Mostrar_botoes,  
        }, () => {
            if (Tempo_fechar > 0){
                setTimeout(
                    this.Fecha_MsgPopUpPadrao.bind(this, 'timer'),
                    Tempo_fechar
                );                
            };
        });
    };

    Fecha_MsgPopUpPadrao(Tipo_botao){
        this.setState({
            State_MsgPopUpPadrao_Visivel: false,               
        }, () => {
            if ((Tipo_botao === 'ok') || (Tipo_botao === 'sim') || (Tipo_botao === 'timer')){
                if (this.state.State_MsgPopUpPadrao_Acao === 'Teste'){
                    //console.log('Roda a Função Teste');
                };
            };
        });
    };
    
    Abre_LoadPopUpPadrao(mensagem){
        this.setState({
            State_LoadPopUpPadrao_Visivel: true,
            State_LoadPopUpPadrao_Mensagem: mensagem,            
        });
    };

    Fecha_LoadPopUpPadrao(){
        this.setState({
            State_LoadPopUpPadrao_Visivel: false,
            State_LoadPopUpPadrao_Mensagem: '',            
        });
    };    
    
    Pesquisar_Usuario(){
        var objJson = { 
            Pesq_Codigo: this.state.state_usuario_id,
        };

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Usuario_Detalhe', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                //console.log(new Date(resposta.dados.data_cadastro).toLocaleDateString("en-CA"));
                //console.log(new Date(resposta.dados.data_cadastro));
                this.setState({
                    state_usuario_id: resposta.dados.codigo,                    
                    state_usuario_nome: resposta.dados.nome,
                    state_usuario_email: resposta.dados.email,
                    state_usuario_ativo: resposta.dados.ativo,
                    state_usuario_data_cadastro: new Date(resposta.dados.data_cadastro).toLocaleDateString("en-CA"),
                }, function (){
                    //
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    };

    Salva_Usuario(){
        if (this.state.state_status_operacao === 'novo'){
            this.Cadastra_usuario();
        }else{
            this.Atualiza_Usuario();
        };
    }

    Cadastra_usuario(){
        //Faz as validações dos campos       
        var objJson = { 
            usuario_id: this.state.state_usuario_id,
            usuario_nome: this.state.state_usuario_nome,
            usuario_email: this.state.state_usuario_email,
            usuario_senha: this.state.state_usuario_senha,
            usuario_ativo: this.state.state_usuario_ativo,
        };        

        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar, 'Usuario_Cadastra', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_usuario_id: resposta.dados.usuario_id,
                    state_status_operacao: 'editar',
                }, ()=>{
                    this.Pesquisar_Usuario();
                    this.Abre_MsgPopUpPadrao(1, false, 'Usuário cadastrado com sucesso!', '', 2000);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }

    Atualiza_Usuario(){
        //Faz as validações dos campos
        var objJson = { 
            usuario_id: this.state.state_usuario_id,
            usuario_nome: this.state.state_usuario_nome,
            usuario_email: this.state.state_usuario_email,
            usuario_ativo: this.state.state_usuario_ativo,
        };        
        this.Abre_LoadPopUpPadrao('Por favor aguarde ...');
        Api.Executa_chamada(
            this.props.navegar,'Usuario_Atualiza', 0, JSON.stringify(objJson)
        ).then((resposta) => {
            this.Fecha_LoadPopUpPadrao();
            if (resposta.status === 1){
                this.setState({
                    state_usuario_id: resposta.dados.usuario_id,
                }, ()=>{
                    this.Pesquisar_Usuario();
                    this.Abre_MsgPopUpPadrao(1, false, 'Usuário Atualizado com sucesso!', '', 2000);
                });
            }else if (resposta.status === 2){
                this.Abre_MsgPopUpPadrao(3, false, resposta.msg, '', 2000)           
            }
            else{
                this.Abre_MsgPopUpPadrao(5, false, resposta.msg, '', 5000)
            };           
        });        
    }
    
    ChkBloqueadoChange(){
        this.setState({state_cliente_bloqueado_checkbox: ''})    
    };

    Atualiza_Validade_Licensa(data_str){
        if (Global_Valida_Data(data_str)){
            this.setState({state_cliente_validade_licenca: data_str});
        }
    };

    render(){
        return(
        <div>
            <MsgPopUpPadrao
                visivel = {this.state.State_MsgPopUpPadrao_Visivel}
                mensagem = {this.state.State_MsgPopUpPadrao_Mensagem}
                tipo = {this.state.State_MsgPopUpPadrao_Tipo}
                mostrar_botoes = {this.state.State_MsgPopUpPadrao_Mostrar_Botoes}
                onclick_btn_ok = {this.Fecha_MsgPopUpPadrao.bind(this, 'ok')}
                onclick_btn_sim = {this.Fecha_MsgPopUpPadrao.bind(this, 'sim')}
                onclick_btn_nao = {this.Fecha_MsgPopUpPadrao.bind(this, 'nao')}
            />
            <PopUpLoadPadrao
                visivel = {this.state.State_LoadPopUpPadrao_Visivel}
                mensagem = {this.state.State_LoadPopUpPadrao_Mensagem}
            />                                 
            <BarraLateral/>
            <div className="GlobalConteinerPrincipal"> 
                <div className="GlobalConteudoPrincipal">

                    <TituloPagina titulo={"Usuario > Cadastro"} />                 
                    <div className={Estilos.ContainerGrid}>
                        <InputPadrao 
                                titulo={'Código'}
                                value = {this.state.state_usuario_id}   
                                disabled = {true}
                        />
                        <InputDataPadrao 
                            titulo = 'Dt. Cadastro'
                            value={this.state.state_usuario_data_cadastro}
                            //onChange={valor => this.Atualiza_Validade_Licensa(valor.target.value)}    
                        /> 
                        <InputPadrao 
                                titulo={'Email'}
                                onChange = {valor => this.setState({state_usuario_email: valor.target.value})}
                                value = {this.state.state_usuario_email}    
                        />                                                  
                        <InputPadrao 
                                titulo={'Nome'}
                                onChange = {valor => this.setState({state_usuario_nome: valor.target.value})}
                                value = {this.state.state_usuario_nome}    
                        />  
                        <InputPadrao 
                            titulo={'Senha'}
                            onChange = {valor => this.setState({state_usuario_senha: valor.target.value})}
                            value = {this.state.state_usuario_senha}
                            disabled = {(this.state.state_status_operacao === 'editar' ? true : false)}    
                        /> 
                        <TooglePadrao
                                descricao={'Ativo'}
                                marcado={this.state.state_usuario_ativo}
                                onClick={() => this.setState({state_usuario_ativo: (this.state.state_usuario_ativo === 'S') ? 'N' : 'S'})}
                        />                                                                                                                                                                                                                                           
                    </div>                                                                                         

                    <div className={Estilos.ConteinerInput1} >
                        <BotaoPadrao                                           
                            tipo={"voltar"} 
                            descricao={"Voltar"} 
                            onClick={() =>{this.props.navegar("/usuarios/consulta")}} 
                        />

                        <BotaoPadrao                   
                            tipo={"salvar"} 
                            descricao={"Salvar"} 
                            onClick={() =>{this.Salva_Usuario()}} 
                        />  
                    </div>                                                                                                   
                </div>
            </div>
            <Rodape/>
        </div>            
        );
    };
};

export default withRouter(ClientesCadastro);