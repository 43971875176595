import "./estilo.css";

function Rodape(){
    return(
        <div className="Rodape">
            <p>Moderno &copy; {new Date().getFullYear()} </p>
        </div>
    );
};

export default Rodape;