import React, {useState, useEffect} from "react";
import {Routes, Route, useLocation } from "react-router-dom";
import { Global_Async_Local_Storage } from "../Funcoes_Globais"
import Produtos1 from "../paginas/produtos/Produtos1";
import Produtos2 from "../paginas/produtos/Produtos2";
import Login from "../paginas/Login";
import ClientesConsulta from "../paginas/clientes/consulta";
import ClientesCadastro from "../paginas/clientes/cadastro";
import UsuariosConsulta from "../paginas/usuarios/consulta";
import UsariosCadastro from "../paginas/usuarios/cadastro";
import LoadInicial from "../paginas/Load_Inicial";

function RotasPrincipais() {
    /*Esse useLocation faz disparar o useEffect sempre que uma rota é alterando, por exemplo do login para outra tela qualquer,
    então estou usando isso pra descobrir se tem o token de login, e liberar ou não as rotas para navegação*/
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const location = useLocation()
    
    useEffect(() => { 
        if (Global_Async_Local_Storage.getItem('access_token') === ''){
            setIsLoggedIn(false);
        }else{
            setIsLoggedIn(true);
        }
        //console.log('handle route change here', location)
        }, [isLoggedIn, location])    

    //<Route path="/clientes/consulta" exact Component={ClientesConsulta}/>

    return(
        <Routes path="/">
            <Route index element={<Login />} />
            <Route path="/login" exact Component={Login}/>
            <Route path="/clientes/consulta" element={isLoggedIn ? <ClientesConsulta/> : <Login/>}/>
            <Route path="/clientes/cadastro" element={isLoggedIn ? <ClientesCadastro/> : <Login/>}/>
            <Route path="/usuarios/consulta" element={isLoggedIn ? <UsuariosConsulta/> : <Login/>}/>
            <Route path="/usuarios/cadastro" element={isLoggedIn ? <UsariosCadastro/> : <Login/>}/>            
            <Route path="/load_inicial" element={isLoggedIn ? <LoadInicial/> : <Login/>}/>                        
            <Route path="/produto/produto1" element={isLoggedIn ? <Produtos1/> : <Login/>}/>
            <Route path="/produto/produto2" element={isLoggedIn ? <Produtos2/> : <Login/>}/>
        </Routes>
    )
}

export default RotasPrincipais;