const CoresGlobal = {
    cor_paleta_1: '#023E8A',
    cor_paleta_2: '#00B2CA',
    cor_paleta_3: '#DB3A34',
    cor_paleta_4: '#20BF55',
    cor_paleta_5: '#F4F7F5',
    cor_paleta_6: '#C2C1C2',    
    cor_paleta_7: '#595959',
    cor_botao_padrao: '#0077c2',
    cor_botao_padrao_enter: '#0077',
  };
  
  export default CoresGlobal;