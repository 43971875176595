import React, { Component } from "react";
import EstiloPadrao from "./estilo.module.css";
//import { FaSearch } from "react-icons/fa";
import { CoresGlobal } from "../../../estilos";

class ComboBoxPadrao extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_titulo: '',
            state_estilo: '',
            state_itens: [],
            state_select_value: '',
            state_onChange: undefined,
            state_dica: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_titulo: props.titulo,
            state_estilo: props.estilo,
            state_itens: props.itens,
            state_select_value: props.select_value,
            state_onChange: props.onChange,            
            state_dica: props.dica, 
        }
    } 

    render(){
        return(
            <div
                className={EstiloPadrao.ContainerInput} 
                style={{'width': '100%', background: CoresGlobal.cor_botao_padrao, border: `solid 0.1em ${CoresGlobal.cor_paleta_6}` }} 
            >
                <div className={EstiloPadrao.ContainerConteudo}>                  
                    {
                        (this.state.state_titulo !== '') ?
                        <div 
                            className={EstiloPadrao.tituloInput} 
                            style={{backgroundColor: CoresGlobal.cor_botao_padrao}}
                        >
                            {this.state.state_titulo}
                        </div>
                        : null
                    }
                    <div>
                        <select className={EstiloPadrao.ComboBox} value={this.state.state_select_value} onChange={this.state.state_onChange}>
                            <option value="0">{this.state.state_dica}</option>
                            {
                                this.state.state_itens ?
                                this.state.state_itens.map((item) =>(  
                                    <option key={item.codigo} value={item.codigo}>{item.descricao}</option>    
                                ))
                                : null
                            }
                        </select>
                    </div>                                               
                </div>
            </div>
        );
    }
};

export default ComboBoxPadrao;