import ItemTabelaPadrao from "../TabelaPadrao/ItemTabelaPadrao";
import { CoresGlobal } from "../../../estilos";
import Estilo from "./estilo.module.css";
import React from "react";

//const TabelaTipo1 = ({id, titulo, Itens, onClick}) =>{
function TabelaPadrao ({titulo, Itens, clickEditar, clickVisualizar, clickExcluir, 
    motrarBtnEditar, motrarBtnVisualizar, motrarBtnExcluir}) {        
    return(
        <div style={{width: '100%', border: `solid 0.1em ${CoresGlobal.cor_paleta_6}`, marginTop: '0.5em', }}>
            {
                (titulo ? 
                <div className={Estilo.TabelaTitulo} style={{backgroundColor: CoresGlobal.cor_botao_padrao}}>
                    {titulo}
                </div>
                : null)
            }                
            <div style={{maxHeight: '15em', minHeight: '5em', overflowY: 'scroll'}}>       
                <div>
                    {
                    Itens.map((item) =>{
                        var objJson = { 
                            MAC: item.mac,
                        };
                        return(                                
                            <ItemTabelaPadrao
                                id = {item.codigo}
                                titulo = {item.nome_computador}
                                Itens={objJson}
                                motrarBtnEditar = {motrarBtnEditar}
                                motrarBtnVisualizar = {motrarBtnVisualizar}
                                motrarBtnExcluir = {motrarBtnExcluir}
                                clickEditar={() =>{clickEditar(item.codigo)}}
                                clickVisualizar={() =>{clickVisualizar(item.codigo)}}
                                clickExcluir={() =>{clickExcluir(item.codigo)}}
                            />
                        )                            
                    })
                    }                                                
                </div>
            </div>
        </div>
    );
};

export default TabelaPadrao;