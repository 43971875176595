export const Global_Valida_Data = (data_str) =>{
    var data = new Date(data_str);
    var validado = false;
    if (!isNaN(data))
        validado = true;

    if (data_str === null)
        validado = false;

    if (data.getFullYear() > 3000)
        validado = false;

    return validado;
};

export const Global_Converte_Data = (data) =>{
    var data_convertida = new Date(data).toLocaleDateString('en-CA');

    if (!data){
        data_convertida = null;
    }else{
        data_convertida = new Date(data).toISOString().slice(0, 10);
    };  

    return data_convertida;
};

export const Global_Async_Local_Storage = {
    setItem(campo, dados) {
        return localStorage.setItem(campo, dados);
    },
    getItem(campo) {
        return localStorage.getItem(campo);
    },
    clear() {
        return localStorage.clear();
    }    
};