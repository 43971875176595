import React, { Component } from "react";
//import { FaPlus, FaSearch, FaSave, FaBackward  } from "react-icons/fa";
//import { CoresGlobal } from "../../../estilos"
//import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import Estilos from "./estilo.module.css";
import ImgGif from "../../../imagens/LoadPopUpPadrao/Blocks-1s-31px+999.gif";



//import Estilo from "./estilo.module.css";

class PopUpLoadPadrao extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_visivel: false,
            state_mensagem: '',
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_visivel: props.visivel,
            state_mensagem: props.mensagem,                      
        }
    }     

    render(){
        return(
            <div>
                {
                    this.state.state_visivel ?
                    <div className={Estilos.FundoModal}>
                        <div className={Estilos.Modal}>
                            <div className={Estilos.Mensagem}>
                                <img src= {ImgGif} 
                                    alt=""
                                    className={Estilos.ImgStatus}
                                />
                                <div>
                                    {this.state.state_mensagem}
                                </div>                                            
                            </div>                    
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }    
};

export default PopUpLoadPadrao;

