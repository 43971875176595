import React, { Component } from "react";
//import { FaPlus, FaSearch, FaSave, FaBackward  } from "react-icons/fa";
import { CoresGlobal } from "../../../estilos"
import BotaoPadrao from "../../../componentes/Botoes/BotaoPadrao";
import Estilos from "./estilo.module.css";
import ImgInformacao from "../../../imagens/MsgPopUpPadrao/imgInformacao.png";
import ImgQuestao from "../../../imagens/MsgPopUpPadrao/imgQuestao.png";
import ImgCuidado from "../../../imagens/MsgPopUpPadrao/imgCuidado.png";
import ImgDeletar from "../../../imagens/MsgPopUpPadrao/imgDeletar.png";
import ImgErro from "../../../imagens/MsgPopUpPadrao/imgErro.png";
import ImgLogo from "../../../imagens/LoadPopUpPadrao/logo.png";


//import Estilo from "./estilo.module.css";

class MsgPopUpPadrao extends Component {
    constructor(props){
        super(props)

        //Este state é necessário para o getDerivedStateFromProps
        this.state ={
            state_visivel: false,
            state_mensagem: '',
            state_tipo: 1,
            state_mostrar_botoes: true,
            state_onclick_btn_ok: null,
            state_onclick_btn_sim: null,
            state_onclick_btn_nao: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            state_visivel: props.visivel,
            state_mensagem: props.mensagem, 
            state_tipo: props.tipo,
            state_mostrar_botoes: props.mostrar_botoes,
            state_onclick_btn_ok: props.onclick_btn_ok,
            state_onclick_btn_sim: props.onclick_btn_sim,
            state_onclick_btn_nao: props.onclick_btn_nao,                      
        }
    }     

    render(){
        return(
            <div>
                {
                    this.state.state_visivel ?
                        <div className={Estilos.FundoModal}>
                        <div className={Estilos.Modal}>
                            <div className={Estilos.Titulo}>
                                <img src= {ImgLogo} style={{width: '2em'}} alt=""/>
                            </div> 
                            <div className={Estilos.ImgStatus}>
                                <img 
                                    src= {
                                            (this.state.state_tipo === 1) ? ImgInformacao :
                                            (this.state.state_tipo === 2) ? ImgQuestao :
                                            (this.state.state_tipo === 3) ? ImgCuidado :
                                            (this.state.state_tipo === 4) ? ImgDeletar :
                                            (this.state.state_tipo === 5) ? ImgErro
                                            :null
                                        } 
                                    alt=""
                                    style={{width: '40px'}}
                                />
                            </div> 
                            <div className={Estilos.Mensagem}>
                                {this.state.state_mensagem}
                            </div>
                            <div className={Estilos.Botoes}>
                                {
                                    (this.state.state_mostrar_botoes) && ((this.state.state_tipo === 1) || (this.state.state_tipo === 3) || (this.state.state_tipo === 5)) ?
                                        <BotaoPadrao 
                                            estilo={{width: '100px','margin-right': '5px', 'background-color': CoresGlobal.cor_paleta_4}}
                                            descricao={"OK"} 
                                            onClick={this.state.state_onclick_btn_ok}                        
                                        /> 
                                        : null                                    
                                }

                                {
                                    (this.state.state_mostrar_botoes) && ((this.state.state_tipo === 2) || (this.state.state_tipo === 4)) ?
                                        <BotaoPadrao 
                                            estilo={{width: '100px','margin-right': '5px', 'background-color': CoresGlobal.cor_paleta_3}}
                                            descricao={"Não"} 
                                            onClick={this.state.state_onclick_btn_nao}
                                        />   
                                        : null                                    
                                }                                  

                                {
                                    (this.state.state_mostrar_botoes) && ((this.state.state_tipo === 2) || (this.state.state_tipo === 4)) ?
                                        <BotaoPadrao 
                                        estilo={{width: '100px', 'background-color': CoresGlobal.cor_paleta_4}}
                                            descricao={"Sim"}
                                            onClick={this.state.state_onclick_btn_sim} 
                                        />
                                        : null                                    
                                }
                                                                
                                                 
                            </div>                                                                                    
                        </div>
                    </div>
                    : null                    
                }
            </div>
        )
    }    
};

export default MsgPopUpPadrao;

