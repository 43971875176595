import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import RotasPrincipais from "./Rotas/rotas";

/*Esse estilo pode ser usado em qualquer outra tela do site, mesmo que na página em questão não tenha esse estilo
global importado. Comigo estava dando conflito no nome das classes, pois o css que era pra ser específico de uma página
estava sendo compartilhada por todas elas, a solucao foi utilizar o .module no nome do arquivo dss, e utilizado*/
import "../src/estilos/EstiloGlobal.css";

/*
node versão v20.15.1

npm install react-router-dom@6.25.1

npm install react-icons@5.2.1

npm install styled-components@6.1.12

npm install axios
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <RotasPrincipais/>
  </BrowserRouter>
);
